var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: { "file-upload-wrap-nobutton": !_vm.showUpload } },
      [
        _c(
          "Upload",
          {
            ref: "upload",
            attrs: {
              action: _vm.uploadFileUrl,
              headers: _vm.accessToken,
              "on-success": _vm.handleSuccess,
              "on-error": _vm.handleError,
              format: _vm.format,
              accept: _vm.accept,
              "max-size": _vm.maxSize * 1024,
              "on-format-error": _vm.handleFormatError,
              "on-exceeded-size": _vm.handleMaxSize,
              "before-upload": _vm.beforeUpload,
              "default-file-list": _vm.fileList,
              "on-preview": _vm.handlePreview,
              "on-remove": _vm.handleRemove,
            },
          },
          [
            _c(
              "Button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showUpload,
                    expression: "showUpload",
                  },
                ],
                attrs: {
                  type: _vm.type,
                  ghost: _vm.ghost,
                  shape: _vm.shape,
                  size: _vm.size,
                  disabled: _vm.disabled,
                  icon: _vm.icon,
                },
              },
              [_vm._v(_vm._s(_vm.text))]
            ),
          ],
          1
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showUpload && _vm.fileList.length < 1,
                expression: "!showUpload && fileList.length < 1",
              },
            ],
          },
          [_vm._v(_vm._s(_vm.noDataText))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }